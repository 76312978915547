import React from "react"
import { graphql, Link } from "gatsby"
import { getCurrentHomepage } from "@bufox/gatsby-utils/fe/wp"
import PropTypes from "prop-types"
import Layout from "components/layouts/base-layout/base-layout"
import SEO from "@bufox/gatsby-utils/fe/wp/wpSeoHelmet"
import MainContent from "components/main-content/main-content"
import styled from "styled-components"
import ArchivePosts from "components/archive-posts/archive-posts"
import themeConfig from "utils/theme-config"

const { screens } = themeConfig

const Category = props => {
  const {
    className,
    location,
    data: {
      wpCategory: {
        name,
        description,
        language,
        acfTaxonomy: { featuredImage },
        posts,
        seo,
        subcategories,
        translations,
      },
      homepages,
      rawMenus,
      searchUrls,
      wpSettings,
      commonThemeSettings,
      themeSettings: {
        acfThemeSettings: {
          category: { postsTitle, subcategoriesTitle },
        },
      },
    },
  } = props
  const {
    acfThemeSettings: {
      generalSettings,
      footer,
      search,
      socialNetworks,
      socialNetworksShare,
    },
  } = commonThemeSettings

  const homepage = getCurrentHomepage(homepages, language.code)

  return (
    <Layout
      className={className}
      generalSettings={generalSettings}
      homepageUri={homepage?.uri}
      footer={footer}
      location={location}
      language={language}
      rawMenus={rawMenus}
      searchSettings={search}
      searchUrls={searchUrls}
      socialNetworks={socialNetworks}
      wpSettings={wpSettings}
      translations={translations}
    >
      <div>
        <SEO
          featuredImage={featuredImage ? featuredImage.node : null}
          language={language}
          excerpt={description}
          seo={seo}
          siteUrl={process.env.GATSBY_SITE_URL}
          title={name}
          translations={translations}
        />
        <MainContent
          breadcrumbs={{ breadcrumbs: seo.breadcrumbs, removeLastLink: true }}
          className="category-content"
          content={description}
          image={featuredImage ? featuredImage.node : null}
          location={location}
          siteUrl={process.env.GATSBY_SITE_URL}
          socialNetworksShare={socialNetworksShare}
          title={name}
        ></MainContent>
        {subcategories.nodes.length ? (
          <div className="sub-categories dry-container ">
            <h2>{subcategoriesTitle.replace("%category%", name)}</h2>
            <ul>
              {subcategories.nodes.map((element, i) => (
                <li key={`subcategory-${i}`}>
                  <Link className="sub-category-link" to={element.uri}>
                    {element.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {posts.nodes.length ? (
          <ArchivePosts
            className="all-posts dry-container"
            name={name}
            posts={posts.nodes}
            replaceString="%category%"
            title={postsTitle}
          ></ArchivePosts>
        ) : null}
      </div>
    </Layout>
  )
}

const StyledCategory = styled(Category)`
  .main-content {
    position: relative;
    z-index: 20;
  }

  .sub-categories {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }

    li {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      font-size: 1.25rem;
      line-height: 1.75rem;
      text-transform: uppercase;
    }
    .sub-category-link {
      padding-bottom: 0.5rem;
      transition-property: background-color, border-color, color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 150ms;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
      border-bottom-width: 2px;
      border-color: transparent;
      &:hover {
        border-color: var(--tertiary);
      }
    }
  }

  @media only screen and (min-width: ${screens.lg}) {
    .sub-categories {
      li {
        width: 50%;
      }
    }
  }
`

Category.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    homepages: PropTypes.object,
    rawMenus: PropTypes.object,
    wpCategory: PropTypes.shape({
      description: PropTypes.string,
      language: PropTypes.object,
      name: PropTypes.string,
      acfTaxonomy: PropTypes.shape({
        featuredImage: PropTypes.object,
      }),
      posts: PropTypes.object,
      seo: PropTypes.object,
      subcategories: PropTypes.object,
      translations: PropTypes.array,
    }),
    wpSettings: PropTypes.object,
    postsFeed: PropTypes.object,
    commonThemeSettings: PropTypes.object,
    searchUrls: PropTypes.object,
    themeSettings: PropTypes.object,
  }),
  location: PropTypes.object,
}

export const pageQuery = graphql`
  query Category(
    $id: String
    $themeSettingsPageId: String
    $menusLocations: [WpMenuLocationEnum]
  ) {
    wpCategory(id: { eq: $id }) {
      name
      description
      subcategories: wpChildren {
        nodes {
          name
          description
          uri
          slug
        }
      }
      posts {
        nodes {
          ...DryMiniPostCardInfo
        }
      }
      language {
        code
        id
        locale
        name
        slug
      }
      acfTaxonomy {
        featuredImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      translations {
        id
        uri
        language {
          code
        }
      }
      seo {
        ...WpTaxonomySeoFields
      }
    }
    themeSettings: wpThemeSettingsPage(id: { eq: $themeSettingsPageId }) {
      acfThemeSettings {
        category {
          postsTitle
          subcategoriesTitle
        }
      }
    }
    ...CommonThemeSettings
    ...Homepages
    ...LocalizedMenus
    ...SearchUrls
    ...WpSettings
  }
`

export default StyledCategory
export { Category }
